<template>
    <section>
        <nav>
            <router-link :to="{ name: 'catalog'}" class="button-styling">Catalog</router-link>
            <div></div>
            <button class="button-styling logout" v-on:click="logout">Logout</button>
        </nav>
        <router-view></router-view>
    </section>
</template>

<script>
    import axios from 'axios';
    export default {
        name: "Education",
        mounted(){
            this.authentication()
        },
        methods: {
            authentication: function(){
                axios
                    .post(process.env.VUE_APP_HOST_ADRES + '/user/authenticate/check',
                        {
                            email: localStorage.getItem('email'),
                            token: localStorage.getItem('user-token')
                        },
                        {
                            headers: {
                                Authorization: 'Bearer ' + localStorage.getItem('user-token'),
                            }
                        }
                    )
                    .then(response => {
                            if (response.data === false) {
                                this.$router.push({ name: "login"})
                            }

                            if (response.data.status === 403) {
                                this.$router.push({ name: "login"})
                            }
                        }
                    )
            },
            logout: function(){
                localStorage.removeItem('email');
                localStorage.removeItem('user-token');
                this.$router.push({ name: "login"})
            }
        }
    }
</script>

<style scoped>
    nav {
        display: grid;
        grid-template-columns: 1fr 8fr 1fr;
        margin-bottom: 25px;
    }
    .button-styling {
        padding: 10px;
        border: 2px solid #59b698;
        color: #2c3e50;
        text-decoration: none;
        display: block;
        cursor: pointer;
    }
    
    .logout {
        /*background: #8d9298;*/
    }

    button{
        border: none;
        width: 100%;
        cursor: pointer;
    }
</style>