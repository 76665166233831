<template>
    <section>
        <h1>Views</h1>
        <section class="video" v-for="video in videos" v-bind:key="video.id">
            <h2>{{ video.name }} - {{ video.category }}</h2>
            <section class="view" v-for="view in getViewsAccordingToVideo(video.id)" v-bind:key="view.id">
                <p>{{view.name}}</p>
                <p>{{new Date(view.datetime).getFullYear()}}-{{new Date(view.datetime).getMonth() + 1}}-{{new Date(view.datetime).getDate()}}
                   {{new Date(view.datetime).getHours()}}:{{('0' + new Date(view.datetime).getMinutes()).slice(-2) }}</p>
            </section>
        </section>
    </section>
</template>

<script>
 import axios from 'axios';
 export default {
  name: "views",
  data (){
   return {
    videos: null,
    views: null
   }
  },
  mounted(){
   this.getVideos()
   this.getViews()
  },
  methods: {
   getViewsAccordingToVideo (video_id) {
    if(this.views === null){
     return;
    }

    return this.views.filter((item) => {
     if (item.video_id === video_id){
      return item
     }
    })
   },
   getVideos(){
    axios
      .get(process.env.VUE_APP_HOST_ADRES + '/videos/categories',
        {
         headers: {
          Authorization: 'Bearer ' + localStorage.getItem('user-token'),
         }
        })
      .then(response => {
       this.videos = response.data
      })
   },
   getViews () {
    axios
      .get(process.env.VUE_APP_HOST_ADRES + '/views',
        {
         headers: {
          Authorization: 'Bearer ' + localStorage.getItem('user-token'),
         }
        })
      .then(response => {
            this.views = response.data
      })
      .catch((error) => {
       if(error.response.status === 400) {
        this.$router.push({ name: "catalog"})
       }
      })
   }
  }
 }
</script>

<style scoped lang="scss">
    .video {
        h2 {
            margin-bottom: 0;
            height: 50px;
            text-align: left;
            padding-left: 30px;
        }
        border: 2px solid #75eec7;
        background: #5dcda9;
        margin-bottom: 50px;
    }
    .view {
        display: grid;
        grid-template-columns: 1fr 1fr;
        height: 50px;
    }
    .view:nth-child(odd){
        background: #5dcda9;
    }
    .view:nth-child(even){
        background: #75eec7;
    }
</style>