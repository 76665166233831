<template>
    <section>
        <section>
            <h2>Upload Video</h2>
            <div>Text field title:
                <input type="text" name="title" v-model="video_name"/>
            </div>
            <div>File:
                <input type="file" ref="file" name="video" v-on:change="handleVideoUpload()"/>
            </div>
            <div>
                Category
                <select v-model="category">
                    <option v-for="category in categories" :key="category.id" v-bind:value="category.id">
                        {{category.name}}
                    </option>
                </select>
            </div>
            <button v-on:click="onSubmitVideo">Upload</button>
            <p v-if="upload_progress !== 0 " :class="{green: upload_progress === 100}">{{ upload_progress }}</p>
        </section>
        <section class="category-adder">
            <h2>Category</h2>
            <div class="text-input">Category: <input type="text" name="name" v-model="category_name"/>
                <button v-on:click="onSubmitCategory">Add</button>
            </div>
            <section class="list-section">
                <section v-for="(category, index) in categories" :key="category.id" class="list category-list">
                    <div class="image-upload">
                        <label :for="`file-input-${index}`">
                            <img :src="getCategoryImage(category.id)" />
                        </label>

                        <input :id="`file-input-${index}`" type="file" ref="imagefile" name="video" v-on:change="handleImageUpload(index, category.id)"/>
                    </div>
                    <p>{{category.name}}</p>
                </section>
            </section>
        </section>
        <section class="word-adder">
            <h2>Word Type</h2>
            <section class="list-section">
                <section v-for="(type, index) in written_word_types" :key="type.id" class="list word-list">
                    <div class="image-upload">
                        <label :for="`word-file-input-${index}`">
                            <img :src="getWordImage(type.id)" />
                        </label>

                        <input :id="`word-file-input-${index}`" type="file" ref="wordFile" name="word" v-on:change="handleWordImageUpload(index, type.id)"/>
                    </div>
                    <p>{{type.name}}</p>
                </section>
            </section>
        </section>
        <section class="group-adder">
            <h2 class="title">Group</h2>
            <div class="text-input">Group: <input type="text" name="name" v-model="group_name"/>
                <button v-on:click="onSubmitGroup">Add</button>
            </div>
            <section class="list-section">
                <section v-for="group in groups" :key="group.id" class="list">
                    <p>{{group.name}}</p>
                </section>
            </section>
        </section>
        <section class="category-group">
            <h2>Access of the groups</h2>
            <section v-for="category in categories" :key="category.id" class="category-group-list list">
                <p class="bold">{{category.name}}</p>
                <section>
                    <section class="checkbox" v-for="group in groups" :key="group.id">
                        <input type="checkbox" :checked="checkboxCategoryValue(category, group)"
                               @change="addCategoryOrRemoveToGroup($event, category.id, group.id)"/>
                        {{ group.name }}
                    </section>
                </section>
            </section>
        </section>
        <section class="user-group">
            <h2>Users</h2>
            <section v-for="user in users" :key="user.id" class="category-group-list list">
                <p class="bold">{{user.name}}</p>
                <section class="checkboxes">
                    <section class="checkbox" v-for="group in groups" :key="group.id">
                        <input type="checkbox" :checked="checkboxUserValue(user, group)"
                               @change="addUserOrRemoveToGroup($event, user.id, group.id)"/>
                        {{ group.name }}
                    </section>
                </section>
            </section>
        </section>
    </section>
</template>

<script>
 import axios from 'axios';

 export default {
  name: "upload",
  data() {
   return {
    category_name: null,
    categories: null,
    written_word_types: null,
    usergroups: null,
    access: null,
    users: null,
    groups: null,
    category: null,
    group_name: null,
    image: null,
    video: '',
    video_name: null,
    upload_progress: 0
   }
  },
  async mounted() {
   await this.getUserGroup();
   await this.getAccess();
   await this.getCategories();
   await this.getWrittenWordTypes()
   await this.getGroups();
   await this.getUsers();
  },
  methods: {
   checkboxCategoryValue: function (category, group) {
    let check = this.access.find(o => o.category === category.id && o.usergroup === group.id);
    if (check == null) {
     return false;
    }

    return true;
   },
   checkboxUserValue: function (user, group) {
    let check = this.usergroups.find(o => o.user === user.id && o.usergroup === group.id);
    if (check == null) {
     return false;
    }

    return true;
   },
   addCategoryOrRemoveToGroup: function (e, category, group) {
    if (e.target.checked === true) {
     axios
       .post(process.env.VUE_APP_HOST_ADRES + '/category/group/add', {
          category,
          group
         },
         {
          headers: {
           Authorization: 'Bearer ' + localStorage.getItem('user-token'),
          }
         }
       );

     return;
    }

    axios
      .post(process.env.VUE_APP_HOST_ADRES + '/category/group/remove', {
         category,
         group
        },
        {
         headers: {
          Authorization: 'Bearer ' + localStorage.getItem('user-token'),
         }
        })

   },
   addUserOrRemoveToGroup: function (e, user, group) {
    if (e.target.checked === true) {
     axios
       .post(process.env.VUE_APP_HOST_ADRES + '/user/group/add', {
          user,
          group
         },
         {
          headers: {
           Authorization: 'Bearer ' + localStorage.getItem('user-token'),
          }
         });

     return;
    }

    axios
      .post(process.env.VUE_APP_HOST_ADRES + '/user/group/remove', {
         user,
         group
        },
        {
         headers: {
          Authorization: 'Bearer ' + localStorage.getItem('user-token'),
         }
        })

   },
   onSubmitCategory: function () {
    axios
      .post(process.env.VUE_APP_HOST_ADRES + '/category', {
         name: this.category_name
        },
        {
         headers: {
          Authorization: 'Bearer ' + localStorage.getItem('user-token'),
         }
        })
      .then(response => (console.log(response)))
   },
   onSubmitGroup: function () {
    axios
      .post(process.env.VUE_APP_HOST_ADRES + '/group', {
         name: this.group_name
        },
        {
         headers: {
          Authorization: 'Bearer ' + localStorage.getItem('user-token'),
         }
        })
      .then(response => (console.log(response)))
   },
   getGroups: function () {
    axios
      .get(process.env.VUE_APP_HOST_ADRES + '/groups',
        {
         headers: {
          Authorization: 'Bearer ' + localStorage.getItem('user-token'),
         }
        })
      .then(response => (this.groups = response.data))
   },
   getCategories: function () {
    axios
      .get(process.env.VUE_APP_HOST_ADRES + '/categories',
        {
         headers: {
          Authorization: 'Bearer ' + localStorage.getItem('user-token'),
         }
        })
      .then(response => (this.categories = response.data))
   },
   getUsers: function () {
    axios
      .get(process.env.VUE_APP_HOST_ADRES + '/users',
        {
         headers: {
          Authorization: 'Bearer ' + localStorage.getItem('user-token'),
         }
        })
      .then(response => {
       this.users = response.data
      })
      .catch((error) => {
       if(error.response.status === 400) {
        this.$router.push({ name: "catalog"})
       }
      })
   },
   getUserGroup: function () {
    axios
      .get(process.env.VUE_APP_HOST_ADRES + '/usergroups',
        {
         headers: {
          Authorization: 'Bearer ' + localStorage.getItem('user-token'),
         }
        })
      .then(response => (this.usergroups = response.data))
   },
   getAccess: function () {
    axios
      .get(process.env.VUE_APP_HOST_ADRES + '/access',
        {
         headers: {
          Authorization: 'Bearer ' + localStorage.getItem('user-token'),
         }
        })
      .then(response => (this.access = response.data))
   },
   getCategoryImage: function (category_id) {
    return process.env.VUE_APP_HOST_ADRES + '/category/image/' + category_id
   },
   onSubmitVideo: function () {
    let formData = new FormData();
    formData.append('video', this.video);
    formData.set('category', this.category);
    formData.set('name', this.video_name);

    this.upload_progress = 0

    const request_config = {
     onUploadProgress: function (progressEvent) {
      self.upload_progress = Math.round((progressEvent.loaded * 100) / progressEvent.total)
     },
     headers: {
      "Content-Type": "multipart/form-data",
      "Authorization": 'Bearer ' + localStorage.getItem('user-token'),
     },
     data: formData
    };

    let self = this;

    axios
      .post(process.env.VUE_APP_HOST_ADRES + '/video', formData, request_config)
      .then(response => (console.log(response)))
   },
   handleVideoUpload: function () {
    this.video = this.$refs.file.files[0];
   },
   handleImageUpload: function (index, category_id) {
    this.image = this.$refs.imagefile[index].files[0]

    let formData = new FormData();
    formData.append('category-image', this.image);
    formData.set('category', category_id);

    const request_config = {
     headers: {
      "Content-Type": "multipart/form-data",
      "Authorization": 'Bearer ' + localStorage.getItem('user-token'),
     },
     data: formData
    };

    axios
      .post(process.env.VUE_APP_HOST_ADRES + '/category/image', formData, request_config)
      .then(this.getCategories())
   },
   handleWordImageUpload: function (index, wordType_id) {
    this.image = this.$refs.wordFile[index].files[0]

    let formData = new FormData();
    formData.append('word-image', this.image);
    formData.set('type_id', wordType_id);

    const request_config = {
     headers: {
      "Content-Type": "multipart/form-data",
      "Authorization": 'Bearer ' + localStorage.getItem('user-token'),
     },
     data: formData
    };

    axios
      .post(process.env.VUE_APP_HOST_ADRES + '/word/type/image', formData, request_config)
      .then(this.getCategories())
   },
   getWrittenWordTypes: function () {
    axios
      .get(process.env.VUE_APP_HOST_ADRES + '/word/types',
        {
         headers: {
          Authorization: 'Bearer ' + localStorage.getItem('user-token'),
         }
        })
      .then(response => (this.written_word_types = response.data.word_types))
   },
   getWordImage: function (word_id) {
    return process.env.VUE_APP_HOST_ADRES + '/word/type/image/' + word_id
   }
  }
 }
</script>

<style scoped lang="scss">
    .bold {
        font-weight: bold;
    }

    button {
        background: #75eec7;
        border: none;
        padding: 4px;
    }

    .word-list, .category-list {
        .image-upload {
            img {
                width: 100%;
            }
        }
        p {
            text-align: left;
            align-self: center;
            margin-left: 20px;
            font-weight: bold;
        }
        display: grid;
        grid-template-columns: 1fr 5fr;
        padding: 10px;
    }

    .category-group, .user-group {
        h2 {
            text-align: left;
        }
        .checkbox {
            display: inline-block;
        }
        .category-group-list {
            p, input {
                margin-left: 10px;
            }
            display: grid;
            grid-template-columns: 1fr 6fr;
            justify-items: start;
            align-items: center;
        }

    }

    .image-upload>input {
        display: none;
    }

    .green {
        color: #5dbe9f;
    }

    .category-adder, .group-adder, .word-adder {
        h2 {
            text-align: left;
        }
        .title {
            grid-area: title;
        }

        .text-input {
            grid-area: input;
            align-self: center;
            justify-self: right;
        }
        .list-section {
            grid-area: list;
        }

        .list-section {
            display: grid;
            width: 100%;
        }

        .list:nth-child(odd) {
            background: #5dbe9f;
        }
        .list:nth-child(even) {
            background: #75eec7;
        }
        display: grid;
        grid-template-areas: "title fill fill input" "list list list list";
        justify-items: start;
    }

</style>