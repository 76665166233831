<template>
    <section class="word">
        <div></div>
        <section>
            <h1>{{ word.title }}</h1>
            <p>{{ word.body }}</p>
        </section>
        <div></div>
    </section>
</template>

<script>
 import axios from 'axios';
 export default {
  name: "written-word",
  data() {
   return {
    word_id: null,
    word: null
   }
  },
  mounted(){
   this.word_id = this.$route.params.id;
   this.getWord(this.word_id)
  },
  methods: {
   getWord(id) {
    axios
      .get(process.env.VUE_APP_HOST_ADRES + '/word/' + id,
        {
         headers: {
          Authorization: 'Bearer ' + localStorage.getItem('user-token'),
         }
        })
      .then(response => {
       this.word = response.data[0]
      })
   },
  }
 }
</script>

<style scoped>
    h1, p {
        text-align: left;
    }

    p {
        white-space: pre-wrap
    }

    .word {
        display: grid;
        grid-template-columns: 1fr 3fr 1fr;
    }
</style>