<template>
    <section>
        <h1>{{category_name}}</h1>
        <section>
            <router-link v-for="word in words" :key="word.id" :to="{ name: 'word', params: { id: word.id }}">
                <section class="word list">
                    <img src="@/assets/bible.png" />
                    <h2>{{word.title}}</h2>
                </section>
            </router-link>
        </section>
    </section>
</template>

<script>
 import axios from 'axios';

 export default {
  name: "written-word",
  data() {
   return {
    category_name: null,
    words: null,
   }
  },
  mounted() {
   this.category = this.$route.params.id;
   this.getCategory(this.category);
   this.getWord(this.category);
  },
  methods: {
   getCategory: function (category) {
    axios
      .get(process.env.VUE_APP_HOST_ADRES + '/word/type/' + category,
        {
         headers: {
          Authorization: 'Bearer ' + localStorage.getItem('user-token'),
         }
        })
      .then(response => (this.category_name = response.data[0].name))
   },
   getWord: function (category) {
    axios
      .get(process.env.VUE_APP_HOST_ADRES + '/word/type/search/' + category,
        {
         headers: {
          Authorization: 'Bearer ' + localStorage.getItem('user-token'),
         }
        })
      .then(response => (this.words = response.data))
   },
  }
 }
</script>

<style scoped lang="scss">
    h1 {
        text-align: left;
    }

    .list {
        img {
            align-self: center;
            width: 100%;
        }
        h2 {
            text-align: left;
            margin-left: 20px;
        }
        display: grid;
        grid-template-columns: 1fr 7fr;
    }

    a {
        .word {
            color: #2c3e50;
            padding: 1px;
            transition: 0.1s all ease;

        }
        .word:hover {
            background: #59b698;
        }
        border-bottom: 1px solid #838383;
        text-decoration: none;
        width: 100%;
        margin-left: auto;
        margin-right: auto;
        display: block;
    }
    a:last-child {
        border-bottom: none !important;
    }
</style>