<template>
  <div id="app">
    <section class="breedte">
      <router-view></router-view>
    </section>
    <!--<div class="bottom-design"></div>-->
  </div>
</template>

<script>
export default {
  name: 'App',
}
</script>

<style>
h1 {
  font-size: 50px;
}

body {
  margin: 0;
}

.breedte {
  width: 60%;
  margin-left: auto;
  margin-right: auto;
}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
  padding-bottom: 200px;
}

.bottom-design {
  position: absolute;
  width: 120%;
  height: 200px;
  z-index: 0;
  background: #5dbe9f;
  -webkit-transform: rotate(-3deg);
  -moz-transform: rotate(-3deg);
  -ms-transform: rotate(-3deg);
  -o-transform: rotate(-3deg);
  transform: rotate(-3deg);
}

@media only screen and (max-width: 600px) {
  .breedte {
    width: 80%;
  }
}
</style>
