<template>
    <section>
        <h1>{{category_name}}</h1>
        <section>
            <router-link v-for="video in videos" :key="video.key" :to="{ name: 'video', params: { id: video.id }}">
                <section class="video list">
                    <img :src="getVideoImage(video.id)"/>
                    <h2>{{video.name}}</h2>
                </section>
            </router-link>
        </section>
    </section>
</template>

<script>
 import axios from 'axios';

 export default {
  name: "category",
  data() {
   return {
    category_name: null,
    categories: null,
    videos: null,
   }
  },
  mounted() {
   this.category = this.$route.params.id;
   this.getCategory(this.category);
   this.getVideos(this.category);
  },
  methods: {
   getCategory: function (category) {
    axios
      .get(process.env.VUE_APP_HOST_ADRES + '/category/' + category,
        {
         headers: {
          Authorization: 'Bearer ' + localStorage.getItem('user-token'),
         }
        })
      .then(response => (this.category_name = response.data[0].name))
   },
   getCategories: function () {
    axios
      .get(process.env.VUE_APP_HOST_ADRES + '/categories/user',
        {
         headers: {
          Authorization: 'Bearer ' + localStorage.getItem('user-token'),
         }
        })
      .then(response => (this.categories = response.data))
   },
   getVideos: function (category) {
    axios
      .get(process.env.VUE_APP_HOST_ADRES + '/videos/category/' + category,
        {
         headers: {
          Authorization: 'Bearer ' + localStorage.getItem('user-token'),
         }
        })
      .then(response => (this.videos = response.data))
   },
   getVideoImage: function (video_id) {
    return process.env.VUE_APP_HOST_ADRES + '/video/thumbnail/' + video_id
   }
  }
 }
</script>

<style scoped lang="scss">
    h1 {
        text-align: left;
    }

    .list {
        img {
            align-self: center;
            width: 100%;
        }
        h2 {
            text-align: left;
            margin-left: 20px;
        }
        display: grid;
        grid-template-columns: 1fr 7fr;
    }

    a {
        .video {
            color: #2c3e50;

            transition: 0.1s all ease;
        }
        overflow: auto;
        height: 80px;
        padding: 10px;
        background: #eeeeee;
        border-bottom: 1px solid #838383;
        text-decoration: none;
        margin-left: auto;
        margin-right: auto;
        display: block;
    }


    a:hover {
        background: #59b698;
    }

    a:last-child {
        border-bottom: none !important;
    }
</style>