<template>
    <section>
        <h1>Education Materials</h1>
        <section class="video-categories">
            <router-link class="category" v-for="category in categories" :key="category.id"
                         :to="{ name: 'category', params: { id: category.id }}">
                <section class="video-link list">
                    <img :src="getCategoryImage(category.id)" />
                    <h2>{{category.name}}</h2>
                </section>
            </router-link>
            <router-link class="category" v-for="type in written_word_types" :key="type.id"
                         :to="{ name: 'wordType', params: { id: type.id }}">
                <section class="video-link list">
                    <img :src="getWordImage(type.id)" />
                    <h2>{{type.name}}</h2>
                </section>
            </router-link>
        </section>
    </section>
</template>

<script>
 import axios from 'axios';

 export default {
  name: "catalog",
  data() {
   return {
    categories: null,
    written_word_types: null,
   }
  },
  mounted() {
   this.getCategories();
   this.getWrittennWordTypes();
  },
  methods: {
   getCategories: function () {
    axios
      .get(process.env.VUE_APP_HOST_ADRES + '/categories/user',
        {
         headers: {
          Authorization: 'Bearer ' + localStorage.getItem('user-token'),
         }
        })
      .then(response => (this.categories = response.data))
   },
   getWrittennWordTypes: function () {
    axios
      .get(process.env.VUE_APP_HOST_ADRES + '/word/types',
        {
         headers: {
          Authorization: 'Bearer ' + localStorage.getItem('user-token'),
         }
        })
      .then(response => (this.written_word_types = response.data.word_types))
   },
   getCategoryImage: function (category_id) {
    return process.env.VUE_APP_HOST_ADRES + '/category/image/' + category_id
   },
   getWordImage: function (type_id) {
    return process.env.VUE_APP_HOST_ADRES + '/word/type/image/' + type_id
   },
  }
 }
</script>

<style scoped lang="scss">
    h1 {
        text-align: left;
        font-size: 30px;
    }

    .list {
        img {
            width: 100%;
            align-self: center;
        }
        h2 {
            text-align: left;
            margin-left: 20px;
        }
        display: grid;
        grid-template-columns: 1fr 7fr;
    }

    .video-categories {
        display: grid;
        grid-template-columns: 1fr;
        align-items: center;
        column-gap: 30px;
        width: 100%;
    }

    a {
        .video-link {
            h2 {
                align-self: center;
            }
            height: inherit;
            color: #2c3e50;
        }
        padding: 10px;
        height: 140px;
        overflow: hidden;
        background: #eeeeee;
        text-decoration: none;
        -webkit-transition: all 0.2s;
        -moz-transition: all 0.2s;
        -ms-transition: all 0.2s;
        -o-transition: all 0.2s;
        transition: all 0.2s;
    }

    a:hover {
        background: #59b698;
    }

    .category {
        border-bottom: 1px solid #838383;
    }

    a:nth-last-child(-n+2) {
        border-bottom: none !important;
    }
</style>