<template>
    <section>
        <section class="video-container">
            <video
                    class="video-view"
                    v-if="video_src"
                    oncontextmenu="return false;"
                    @play="startPlay"
                    @timeupdate="updateProcent"
                    controls
                    controlsList="nodownload"
            >
                <source :src="video_src" type="video/mp4">
            </video>
            <h2>{{video_name}}</h2>
        </section>
    </section>
</template>

<script>
 import axios from 'axios';

 export default {
  name: "WebVideo",
  data() {
   return {
    video_id: null,
    video_name: null,
    video_filename: null,
    video_src: null,
    token: null,
    view_send: false,
    total_watch_time: 0,
    video_time: 0,
    view_id: null,
    procent_watched: 0,
    view_procent_watched: 0,
    polling: null
   }
  },
  async mounted() {
   this.video_id = this.$route.params.id;
   await this.getUrl(this.video_id);
   await this.findOrCreateView(this.video_id);
  },
  created() {
   this.pollData()
  },
  beforeDestroy() {
   clearInterval(this.polling)
  },
  methods: {
   pollData() {
    this.polling = setInterval(() => {
     this.sendProcent()
     this.sendView()
    }, 3000)
   },
   startPlay(event) {
    this.video_time = event.target.currentTime
   },
   sendView() {
    if (
      this.getCombinedProcent() < 80 ||
      this.view_send === true
    ) {
     return;
    }
    this.setVideoToWatched()
    this.view_send = true
   },
   updateProcent(event) {
    if ((event.target.currentTime - this.video_time) > 10) {
     return
    }

    if ((event.target.currentTime - this.video_time) < 0.5) {
     return
    }

    this.total_watch_time += event.target.currentTime - this.video_time;
    this.video_time = event.target.currentTime;
    this.procent_watched = this.total_watch_time / event.target.duration * 100;
   },
   getCombinedProcent() {
    if (typeof this.view_procent_watched === 'undefined') {
     return this.procent_watched
    }
    return this.view_procent_watched + this.procent_watched
   },
   sendProcent() {
    if (this.getCombinedProcent() > 100) {
     return;
    }

    const view = {
     view_id: this.view_id,
     procent_watched: this.getCombinedProcent()
    }

    axios
      .post(
        process.env.VUE_APP_HOST_ADRES + '/view/update/time',
        view,
        {
         headers: {
          Authorization: 'Bearer ' + localStorage.getItem('user-token'),
         }
        })
   },
   getUrl(videoId) {
    axios
      .get(process.env.VUE_APP_HOST_ADRES + '/video/' + videoId,
        {
         headers: {
          Authorization: 'Bearer ' + localStorage.getItem('user-token'),
         }
        })
      .then(response => {
       this.video_filename = response.data.videos[0].filename;
       this.video_name = response.data.videos[0].name;
       this.token = response.data.streamToken
       this.getStreamLink(this.token, this.video_filename);
      })
   },
   setVideoToWatched() {
    const self = this;
    axios
      .post(
        process.env.VUE_APP_HOST_ADRES + '/view/watched',
        {
         view_id: self.view_id,
        },
        {
         headers: {
          Authorization: 'Bearer ' + localStorage.getItem('user-token'),
         }
        })
   },
   findOrCreateView(video_id) {
    axios
      .post(
        process.env.VUE_APP_HOST_ADRES + '/view/new',
        {video_id},
        {
         headers: {
          Authorization: 'Bearer ' + localStorage.getItem('user-token'),
         }
        })
      .then(response => {
       this.view_id = response.data.id
       this.view_procent_watched = response.data.procent_watched
      })
   },
   getStreamLink(tokenn, filename) {
    this.video_src = process.env.VUE_APP_HOST_ADRES + "/video/stream/" + tokenn + '/' + filename;
   },
  }
 }
</script>

<style scoped lang="scss">
    .video-view {
        display: block;
        width: 100%;
        max-height: 500px;
        background: #000;
    }

    h2 {
        text-align: left;
    }

    @media only screen and (max-width: 600px) {
        .video-view {
            width: 100%;
        }
    }
</style>