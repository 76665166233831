<template>
    <section>
        <h1>Install New Password</h1>
        <h2>Email: {{email}}</h2>
        <p v-show="error">Password is not the same</p>
        <section class="password-form">
            <p>Fill in your password for your account on the intranet.</p>
            <section class="password">
                <label>Password</label>
                <input type="password" v-model="password" />
            </section>
            <section class="repeat-password">
                <label>Repeat Password</label>
                <input type="password" v-model="repeatPassword" />
            </section>
            <button @click="setPassword">Set Password</button>
        </section>
    </section>
</template>

<script>
 import axios from 'axios'
 export default {
  name: "reset-password",
  data() {
   return {
    email: null,
    id: null,
    user_id: null,
    password: null,
    repeatPassword: null,
    error: false
   }
  },
  created () {
   this.id = this.$route.params.id;
   this.getNewPassword(this.id)
  },
  methods: {
   getNewPassword: function (id) {
    axios
      .get(process.env.VUE_APP_HOST_ADRES + '/new-password/' + id,
        {
         headers: {
          Authorization: 'Bearer ' + localStorage.getItem('user-token'),
         }
        })
      .then(response => {
        this.email = response.data[0].email
        this.user_id = response.data[0].user_id
      })
   },
   setPassword: function () {
    this.error = false
    if(this.password !==  this.repeatPassword){
     this.error = true
     return;
    }

    const newPassword = {
     newPassword_id: this.id,
     user_id: this.user_id,
     password: this.password
    };

    axios
      .post(process.env.VUE_APP_HOST_ADRES + '/new-password/set',
        newPassword,
        {
         headers: {
          Authorization: 'Bearer ' + localStorage.getItem('user-token'),
         }
        })
      .then(response => {
       if(response.data === "failed"){
        return
       }
       this.$router.push({ name: "login"})
      })
   }
  }
 }
</script>

<style scoped lang="scss">
    .password-form {
        display: grid;
        width: 500px;
        margin-left: auto;
        margin-right: auto;
        grid-template-areas: 'A A A'
                             'B B B'
                             'C C C'
                             'F F G';
        p {
            grid-area: A;
        }
        .password {
            grid-area: B;
        }
        .repeat-password {
            grid-area: C;
        }
        button {
            grid-area: G;
            margin-top: 10px;
        }
        .password, .repeat-password {
            display: grid;
            grid-template-columns: 1fr 2fr;
            text-align: left;
            margin-top: 10px;
        }
    }



</style>