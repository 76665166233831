<template>
    <section>
        <h1>PureLife Stream - Login</h1>
        <section class="layout">
                <section class="error-message" v-bind:class="{ showError: show }">Wrong Login</section>
                <section class="inputs">
                    <section class="input-grid">
                        <h2>Email</h2>
                        <input v-model="email"  v-on:keyup.enter="login" type="text"/>
                        <div></div>
                    </section>
                    <section class="input-grid">
                        <h2>Password</h2>
                        <input v-model="password" v-on:keyup.enter="login"  type="password"/>
                        <div></div>
                    </section>
                </section>
                <section class="login">
                    <button v-on:click="login">Login</button>
                </section>
        </section>
    </section>
</template>

<script>
    import axios from 'axios';

    export default {
        name: "Login",
        data(){
            return {
                email: null,
                password: null,
                show: false
            }
        },
        methods: {
            login(){
                axios
                    .post(process.env.VUE_APP_HOST_ADRES + '/user/authenticate', {
                        email: this.email,
                        password: this.password
                    })
                    .then(response => {
                        if(response.data === "Login failed"){
                            this.show = true;
                            return;
                        }
                        localStorage.setItem('email', this.email);
                        localStorage.setItem('user-token', response.data);
                        this.$router.push({ name: "catalog"})
                    })
            }
        }
    }
</script>

<style scoped lang="scss">
    .layout {
        display: grid;
        grid-gap: 1rem;
        grid-template-columns:  repeat(5, 150px);
        grid-template-rows: repeat(5, 100px);
    }
    h1 {
        text-align: left;
    }
    .error-message {
        color: #e05f55;
        opacity: 0;
        grid-column: 2/5;
        grid-row: 1/2;
    }
    .showError {
        opacity: 1;
    }
    input {
        height: 30px;
    }
    .inputs {
        grid-column: 2/5;
        grid-row: 2/3;
    }
    .login {
        button {
            width: 100%;
            height: 40px;
            background: #75eec7;
            border: none;
            cursor: pointer;
        }
        button:hover {
            background: #59b698;
        }
        grid-column: 4/5;
        grid-row: 4/5;
    }
    .input-grid {
        h2 {
            text-align: left;
        }
        display: grid;
        grid-template-columns: 2fr 2fr ;
        align-items: center;
    }

    @media only screen and (max-width: 600px) {
        .layout {
            display: block;
            grid-gap: 1rem;
            grid-template-columns:  repeat(5, 150px);
            grid-template-rows: repeat(5, 100px);
        }
    }
</style>