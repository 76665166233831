import Vue from 'vue'
import App from './App.vue'
import VueRouter from 'vue-router';
import Login from './components/Login';
import Education from './components/Education/Education';
import Catalog from './components/Education/Catalog';
import Category from './components/Education/Category';
import WebVideo from './components/Education/WebVideo';
import Upload from './components/Education/Upload';
import Users from './components/users';
import UploadWord from './components/Education/UploadWord';
import WrittenWordType from './components/Education/WrittenWordType';
import WrittenWord from './components/Education/WrittenWord';
import Views from './components/Education/views';
import ResetPassword from './components/resetPassword';

Vue.config.productionTip = false;

Vue.use(VueRouter);

const routes = [
 {
  path: '/', redirect: 'login'
 },
 {
  path: '/login', name: 'login', component: Login,
 },
 {
  path: '/users', name: 'users', component: Users,
 },
 {
  path: '/reset-password/:id', name: 'reset-password', component: ResetPassword,
 },
 {
  path: '/education', component: Education,
  children: [
   {
    path: 'catalog', name: 'catalog', component: Catalog
   },
   {
    path: 'category/:id', name: 'category', component: Category
   },
   {
    path: 'word/:id', name: 'word', component: WrittenWord
   },
   {
    path: 'word/type/:id', name: 'wordType', component: WrittenWordType
   },
   {
    path: 'video/:id', name: 'video', component: WebVideo
   },
   {
    path: 'upload', name: 'upload', component: Upload,
   },
   {
    path: 'upload/word', name: 'UploadWord', component: UploadWord
   },
   {
    path: 'views', name: 'views', component: Views
   }
  ]
 }
];

const router = new VueRouter({
 routes
});

new Vue({
 render: h => h(App),
 router
}).$mount('#app');


